import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/78a07ce7/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "grid"
    }}>{`Grid`}</h2>
    <h3 {...{
      "id": "12-columns"
    }}>{`12 columns`}</h3>
    <div className="row">
  <div className="column xlarge-1 large-2 medium-3 small-12">
    <div className="columns">
      <p>1</p>
    </div>
  </div>
  <div className="column xlarge-1 large-2 medium-3 small-12">
    <div className="columns">
      <p>2</p>
    </div>
  </div>
  <div className="column xlarge-1 large-2 medium-3 small-12">
    <div className="columns">
      <p>3</p>
    </div>
  </div>
  <div className="column xlarge-1 large-2 medium-3 small-12">
    <div className="columns">
      <p>4</p>
    </div>
  </div>
  <div className="column xlarge-1 large-2 medium-3 small-12">
    <div className="columns">
      <p>5</p>
    </div>
  </div>
  <div className="column xlarge-1 large-2 medium-3 small-12">
    <div className="columns">
      <p>6</p>
    </div>
  </div>
  <div className="column xlarge-1 large-2 medium-3 small-12">
    <div className="columns">
      <p>7</p>
    </div>
  </div>
  <div className="column xlarge-1 large-2 medium-3 small-12">
    <div className="columns">
      <p>8</p>
    </div>
  </div>
  <div className="column xlarge-1 large-2 medium-3 small-12">
    <div className="columns">
      <p>9</p>
    </div>
  </div>
  <div className="column xlarge-1 large-2 medium-3 small-12">
    <div className="columns">
      <p>10</p>
    </div>
  </div>
  <div className="column xlarge-1 large-2 medium-3 small-12">
    <div className="columns">
      <p>11</p>
    </div>
  </div>
  <div className="column xlarge-1 large-2 medium-3 small-12">
    <div className="columns">
      <p>12</p>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container">
  <div class="row">
    <div class="column xlarge-1 large-2 medium-3 small-12">
      1
    </div>
    <div class="column xlarge-1 large-2 medium-3 small-12">
      2
    </div>
    <div class="column xlarge-1 large-2 medium-3 small-12">
      3
    </div>
    <div class="column xlarge-1 large-2 medium-3 small-12">
      4
    </div>
    <div class="column xlarge-1 large-2 medium-3 small-12">
      5
    </div>
    <div class="column xlarge-1 large-2 medium-3 small-12">
      6
    </div>
    <div class="column xlarge-1 large-2 medium-3 small-12">
      7
    </div>
    <div class="column xlarge-1 large-2 medium-3 small-12">
      8
    </div>
    <div class="column xlarge-1 large-2 medium-3 small-12">
      9
    </div>
    <div class="column xlarge-1 large-2 medium-3 small-12">
      10
    </div>
    <div class="column xlarge-1 large-2 medium-3 small-12">
      11
    </div>
    <div class="column xlarge-1 large-2 medium-3 small-12">
      12
    </div>
  </div>
</div>
`}</code></pre>
    <h3 {...{
      "id": "2-columns-each"
    }}>{`2 columns each`}</h3>
    <div className="row">
  <div className="column xlarge-2 medium-4 small-12">
    <div className="columns">
      <p>2 columns</p>
    </div>
  </div>
  <div className="column xlarge-2 medium-4 small-12">
    <div className="columns">
      <p>2 columns</p>
    </div>
  </div>
  <div className="column xlarge-2 medium-4 small-12">
    <div className="columns">
      <p>2 columns</p>
    </div>
  </div>
  <div className="column xlarge-2 medium-4 small-12">
    <div className="columns">
      <p>2 columns</p>
    </div>
  </div>
  <div className="column xlarge-2 medium-4 small-12">
    <div className="columns">
      <p>2 columns</p>
    </div>
  </div>
  <div className="column xlarge-2 medium-4 small-12">
    <div className="columns">
      <p>2 columns</p>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container">
  <div class="row">
    <div class="column xlarge-2 medium-4 small-12">
      2 columns
    </div>
    <div class="column xlarge-2 medium-4 small-12">
      2 columns
    </div>
    <div class="column xlarge-2 medium-4 small-12">
      2 columns
    </div>
    <div class="column xlarge-2 medium-4 small-12">
      2 columns
    </div>
    <div class="column xlarge-2 medium-4 small-12">
      2 columns
    </div>
    <div class="column xlarge-2 medium-4 small-12">
      2 columns
    </div>
  </div>
</div>
`}</code></pre>
    <h3 {...{
      "id": "3-columns-each"
    }}>{`3 columns each`}</h3>
    <div className="row">
  <div className="column xlarge-3 medium-6 small-12">
    <div className="columns">
      <p>3 columns</p>
    </div>
  </div>
  <div className="column xlarge-3 medium-6 small-12">
    <div className="columns">
      <p>3 columns</p>
    </div>
  </div>
  <div className="column xlarge-3 medium-6 small-12">
    <div className="columns">
      <p>3 columns</p>
    </div>
  </div>
  <div className="column xlarge-3 medium-6 small-12">
    <div className="columns">
      <p>3 columns</p>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container">
  <div class="row">
    <div class="column xlarge-3 medium-6 small-12">
      3 columns
    </div>
    <div class="column xlarge-3 medium-6 small-12">
      3 columns
    </div>
    <div class="column xlarge-3 medium-6 small-12">
      3 columns
    </div>
    <div class="column xlarge-3 medium-6 small-12">
      3 columns
    </div>
  </div>
</div>
`}</code></pre>
    <h3 {...{
      "id": "4-columns-each"
    }}>{`4 columns each`}</h3>
    <div className="row">
  <div className="column xlarge-4 small-12">
    <div className="columns">
      <p>4 columns</p>
    </div>
  </div>
  <div className="column xlarge-4 small-12">
    <div className="columns">
      <p>4 columns</p>
    </div>
  </div>
  <div className="column xlarge-4 small-12">
    <div className="columns">
      <p>4 columns</p>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container">
  <div class="row">
    <div class="column xlarge-4 small-12">
      4 columns
    </div>
    <div class="column xlarge-4 small-12">
      4 columns
    </div>
    <div class="column xlarge-4 small-12">
      4 columns
    </div>
  </div>
</div>
`}</code></pre>
    <h3 {...{
      "id": "5-columns--7-columns"
    }}>{`5 columns / 7 columns`}</h3>
    <div className="row">
  <div className="column xlarge-5 small-12">
    <div className="columns">
      <p>5 columns</p>
    </div>
  </div>
  <div className="column xlarge-7 small-12">
    <div className="columns">
      <p>7 columns</p>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container">
  <div class="row">
    <div class="column xlarge-5 small-12">
      5 columns
    </div>
    <div class="column xlarge-7 small-12">
      7 columns
    </div>
  </div>
</div>
`}</code></pre>
    <h3 {...{
      "id": "6-columns-each"
    }}>{`6 columns each`}</h3>
    <div className="row">
  <div className="column xlarge-6 small-12">
    <div className="columns">
      <p>6 columns</p>
    </div>
  </div>
  <div className="column xlarge-6 small-12">
    <div className="columns">
      <p>6 columns</p>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container">
  <div class="row">
    <div class="column xlarge-6 small-12">
      6 columns
    </div>
    <div class="column xlarge-6 small-12">
      6 columns
    </div>
  </div>
</div>
`}</code></pre>
    <h3 {...{
      "id": "7-columns--5-columns"
    }}>{`7 columns / 5 columns`}</h3>
    <div className="row">
  <div className="column xlarge-7 small-12">
    <div className="columns">
      <p>7 columns</p>
    </div>
  </div>
  <div className="column xlarge-5 small-12">
    <div className="columns">
      <p>5</p>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container">
  <div class="row">
    <div class="column xlarge-7 small-12">
      7 columns
    </div>
    <div class="column xlarge-5 small-12">
      5 columns
    </div>
  </div>
</div>
`}</code></pre>
    <h3 {...{
      "id": "8-columns--4-columns"
    }}>{`8 columns / 4 columns`}</h3>
    <div className="row">
  <div className="column xlarge-8 small-12">
    <div className="columns">
      <p>8 columns</p>
    </div>
  </div>
  <div className="column xlarge-4 small-12">
    <div className="columns">
      <p>4</p>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container">
  <div class="row">
    <div class="column xlarge-8 small-12">
      8 columns
    </div>
    <div class="column xlarge-4 small-12">
      4 columns
    </div>
  </div>
</div>
`}</code></pre>
    <h3 {...{
      "id": "9-columns--3-columns"
    }}>{`9 columns / 3 columns`}</h3>
    <div className="row">
  <div className="column xlarge-9 small-12">
    <div className="columns">
      <p>9 columns</p>
    </div>
  </div>
  <div className="column xlarge-3 small-12">
    <div className="columns">
      <p>3</p>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container">
  <div class="row">
    <div class="column xlarge-9 small-12">
      9 columns
    </div>
    <div class="column xlarge-3 small-12">
      3 columns
    </div>
  </div>
</div>
`}</code></pre>
    <h3 {...{
      "id": "10-columns--2-columns"
    }}>{`10 columns / 2 columns`}</h3>
    <div className="row">
  <div className="column xlarge-10 small-12">
    <div className="columns">
      <p>10 columns</p>
    </div>
  </div>
  <div className="column xlarge-2 small-12">
    <div className="columns">
      <p>2</p>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container">
  <div class="row">
    <div class="column xlarge-10 small-12">
      10 columns
    </div>
    <div class="column xlarge-2 small-12">
      2 columns
    </div>
  </div>
</div>
`}</code></pre>
    <h3 {...{
      "id": "11-columns--1-column"
    }}>{`11 columns / 1 column`}</h3>
    <div className="row">
  <div className="column xlarge-11 small-12">
    <div className="columns">
      <p>11 columns</p>
    </div>
  </div>
  <div className="column xlarge-1 small-12">
    <div className="columns">
      <p>1</p>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container">
  <div class="row">
    <div class="column xlarge-11 small-12">
      11 columns
    </div>
    <div class="column xlarge-1 small-12">
      1 column
    </div>
  </div>
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      